import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useState } from 'react';

Router.events.on('routeChangeStart', url => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function Navigation(params) {
	const [isActive, setIsActive] = useState(false);

	const toggleIsActive = value => {
		if (typeof value === 'boolean') {
			setIsActive(value);
			return;
		}
		setIsActive(!isActive);
	};

	return (
		<nav className="navbar is-black" role="navigation" aria-label="main navigation">
			<div className="container">
				<div className="navbar-brand">
					<Link href="/">
						<a
							className="navbar-item is-unselectable"
							onClick={e => toggleIsActive(false)}>
							<strong className="is-3">ALMESSADI</strong>
						</a>
					</Link>

					<a
						role="button"
						className={`navbar-burger burger${isActive ? ' is-active' : ''}`}
						aria-label="menu"
						aria-expanded="false"
						data-target="navbarmenu"
						onClick={toggleIsActive}>
						<span aria-hidden="true" onClick={toggleIsActive} />
						<span aria-hidden="true" onClick={toggleIsActive} />
						<span aria-hidden="true" onClick={toggleIsActive} />
					</a>
				</div>

				<div id="navbarmenu" className={`navbar-menu${isActive ? ' is-active' : ''}`}>
					<div className="navbar-end">
						<Link href="/#about">
							<a className="navbar-item" onClick={toggleIsActive}>
								About
							</a>
						</Link>
						{/* <Link href="/blog">
							<a className="navbar-item" onClick={ toggleIsActive }>Blog</a>
						</Link> */}
						<Link href="/contact">
							<a className="navbar-item" onClick={toggleIsActive}>
								Contact
							</a>
						</Link>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default Navigation;
