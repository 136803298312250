import App from 'next/app';
import '../styles/main.scss';
import { AnimatePresence } from 'framer-motion';
import Router from 'next/router';
import { Fragment } from 'react';
import { DefaultSeo } from 'next-seo';
import Navigation from '../components/navigation';
import { getCookies } from '../utils/auth';
import { trackPageView } from '../utils/analytics';
import SEO from '../utils/next-seo.config';

export default class extends App {
	static async getInitialProps({ Component, ctx }) {
		let pageProps = {};

		if (Component.getInitialProps) {
			pageProps = await Component.getInitialProps(ctx);
		}
		const cookies = getCookies();

		return { pageProps, appProps: cookies };
	}

	componentDidMount() {
		Router.onRouteChangeComplete = url => {
			trackPageView(url);
		};
	}

	render() {
		const { Component, pageProps, appProps, router } = this.props;
		return (
			<>
				<DefaultSeo {...SEO} />
				<AnimatePresence exitBeforeEnter>
					<Navigation />
					<Component {...pageProps} {...appProps} key={router.route} />
				</AnimatePresence>
			</>
		);
	}
}
