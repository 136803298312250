export default {
	openGraph: {
		type: 'website',
		title: 'Alaeddine Messadi: Full stack Software and Web engineer',
		description: 'Alaeddine Messadi: Full stack Software and Web engineer',
		locale: 'en_IE',
		url: 'https://almessadi.com',
		canonical: 'almessadi.com',
		site_name: 'Alaeddine Messadi',
		images: [
			{
				url:
					'https://avatars0.githubusercontent.com/u/10847134?s=460&u=f18f536792c311be2fbc62e7274773488277061d&v=4',
				alt: 'Alaeddine Messadi'
			}
		]
	},
	description: 'Alaeddine Messadi: Full stack Software and Web engineer',
	twitter: {
		handle: '@aladdindev',
		site: '@aladdindev',
		cardType: 'summary_large_image'
	}
};
